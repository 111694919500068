import { makeStyles, TextField, Typography } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import SERVICE from "../../helper/service";
import API from "../../helper/rest";
import { InputAdornment } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import StyledChipGroupObj from "../Chips/StyledChipGroupObj";
import { meta } from "../Constant";
import StyledChipGroupObjMultiple from "../Chips/StyledChipGroupObjMultiple";
import PlacesInput from "../Input/PlacesInput";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    background: {
      backgroundColor: "#f9f9f9",
      height: "100vh",
    },
    containerSection: {
      background: "#fff",
      padding: "1.5rem 1.5rem",
    },
    rightback: {
      background: "#fff",
      padding: "1.5rem 1.5rem",
      overflowY: "scroll",
      height: "100vh",
    },
  })
);

const validationSchema = yup.object({
  rentAmount: yup
    .number()
    .typeError("Rent amount must be a number")
    .required("Rent amount is required")
    .min(1000, "Amount should be of minimum 1000"),
  advanceAmount: yup
    .number("Enter advance Amount")
    .typeError("Advance amount must be a number")
    .required("Advance amount is required"),
  areaSize: yup
    .number("Enter area size")
    .typeError("Area size must be a number")
    .required("Area size is required"),
  maintenanceCharge: yup
    .number("Enter maintenance charge")
    .typeError("Maintenance charge must be a number")
    .required("Maintenance charge is required"),
  contactNumber: yup
    .number("Enter contact number")
    .typeError("Contact number must be a number")
    .required("Contact number is required"),
});
// const chipDefaults = {
//   propertyType: property.propertyType.id,
//   bathRoom: property.propertyType.id,
//   bhkType: property.propertyType.id,
//   furnishType: property.propertyType.id,
//   openParking: property.propertyType.id,
//   propertyAge: property.propertyType.id,
//   tenantType: property.propertyType.id,
//   agreementDuration: property.propertyType.id,
//   amenity: property.propertyType.id,
// };
// {
//   rentAmount: property.rentAmount ? property.rentAmount : "",
//   advanceAmount: property.advanceAmount ? property.advanceAmount : "",
//   propertyArea: property.propertyArea ? property.propertyArea : "",
//   propertyDesc: property.propertyDesc ? property.property : "",
//   maintenanceCost: property.maintenanceCost ? property.maintenanceCost : "",
//   contactNumber: property.contactNumber ? property.contactNumber : "",
// }
export default function AddOrEditPropertyForm(props) {
  const classes = useStyles();
  const params = useLocation();
  const history = useHistory();
  const selectedPty = useStoreState((state) => state.propertyMap.property);
  const propertyId = selectedPty?.property?.propertyId;
  if (!propertyId) {
    alert("error");
  }

  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState(null);
  const [showError, setShowError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const images = useStoreState((state) => state.image.item);
  const resetImages = useStoreActions((action) => action.image.save);

  const formik = useFormik({
    initialValues: {
      rentAmount: "",
      advanceAmount: "",
      propertyArea: "",
      propertyDesc: "",
      maintenanceCost: "",
      contactNumber: "",
      propertyAvailableDate: selectedDate,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (formik.errors == {}) {
        setShowError(true);
        return;
      }
      saveNewProperty(values);
    },
  });
  const addMyProperty = useStoreActions((action) => action.myProperty.add);

  const [chips, setChips] = useState({
    propertyType: null,
    bathRoom: null,
    bhkType: null,
    furnishType: null,
    openParking: null,
    propertyAge: null,
    tenantType: null,
    agreementDuration: null,
    amenity: null,
  });

  const onChangeChipValue = (name, val) => {
    setChips({ ...chips, [name]: val });
  };
  const onChangeChipValueMultiple = (name, amenity) => {
    var c = chips.amenity == null ? [] : chips.amenity;
    c.push({
      propertyAmenityId: amenity.id,
    });
    setChips({ ...chips, [name]: c });
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const mergeData = (chip, form) => {
    let imgs = SERVICE.getImages(images);
    let d = {
      ...chip,
      ...form,
      ...{
        propertyAddress: location ? location.address : null,
        propertyLatitude: location ? location.lat : null,
        propertyLongitude: location ? location.lng : null,
        image: imgs,
        parentImage: imgs[0].url,
      },
    };
    return d;
  };

  const saveNewProperty = (form) => {
    props.onLoading(true);
    var input = mergeData(chips, form);
    const isEmpty = Object.values(input).every((x) => x === null || x === "");
    if (isEmpty) {
      setShowError(true);
      document.getElementById("submit-btn").disabled = false;
      return;
    }
    const mobile = localStorage.getItem("user");
    if (mobile) {
      API.post("/property/addproperty", {
        ...input,
        ownerMobile: mobile,
        createdBy: mobile,
        createdAt: new Date(),
      })
        .then((response) => {
          const res = SERVICE.processResponse([response.data.response]);
          addMyProperty(res);
          resetImages([0, 1, 2, 3, 4, 5]);
          history.replace({ pathname: "/my-property", state: { from: "ADD" } });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          props.onLoading(false);
        });
    } else {
      alert("Please login");
    }
  };
  const handleLocation = (location) => {
    setLocation({
      address: location.formatted_address,
      lat: location.geometry.location.lat(),
      lng: location.geometry.location.lng(),
    });
    props.onLatLongFound({
      address: location.formatted_address,
      lat: location.geometry.location.lat(),
      lng: location.geometry.location.lng(),
    });
  };

  useEffect(() => {
    setLoading(true);
    if (propertyId) {
      API.get("/property/getpropertydetail?propertyid=" + propertyId)
        .then((response) => {
          let result = SERVICE.processResponse([response.data.response]);
          setProperty(result[0]);
          setChips({
            propertyType: result[0].propertyType,
            bathRoom: result[0].bathRoom,
            bhkType: result[0].bhkType,
            furnishType: result[0].furnishType,
            openParking: result[0].openParking,
            propertyAge: result[0].propertyAge,
            tenantType: result[0].tenantType,
            agreementDuration: result[0].agreementDuration,
            amenity: result[0].amenity,
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("Error in property id");
    }
  }, [propertyId]);
  return (
    <div>
      {!loading && (
        <form id="edit-property" onSubmit={formik.handleSubmit}>
          <Typography className={classes.listHeader}>Location</Typography>
          <PlacesInput
            defaultValue={location}
            onChange={(l) => handleLocation(l)}
          />

          <div>
            <center>
              <span style={{ fontSize: "10px" }}>Or</span>
            </center>
            <div>
              <center>
                {/* <Button
                startIcon={<GpsFixedIcon />}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  getCurrentPosition();
                }}
              >
                current location
              </Button> */}
              </center>
            </div>
          </div>
          {/* <LocationBar /> */}
          {/* <div className={classes.InputBox}>
          <AutoCompleteSearch
            value={location}
            onChange={(loc) => setLocation(loc)}
          />
        </div> */}
          {/* <Typography className={classes.listHeader}>
        Distance Radius with In
      </Typography> */}
          {/* <TextField
        className={classes.inputfiled}
        fullWidth
        name="km"
        size="small"
        type="km"
        InputProps={{
          style: {
            height,
          },
          classes: {
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
          },
          startAdornment: <InputAdornment position="start">km</InputAdornment>,
        }}
        variant="outlined"
      /> */}
          <Typography className={classes.listHeader}>Property Type</Typography>

          <div className={classes.listChip}>
            <StyledChipGroupObj
              options={meta.propertyType}
              onSelect={onChangeChipValue}
              default={chips.propertyType}
              name="propertyType"
              value={chips.propertyType}
            />
          </div>
          <Typography className={classes.listHeader}>Bedroom</Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              options={meta.bhkType}
              onSelect={onChangeChipValue}
              default={chips.bhkType}
              name="bhkType"
              value={chips.bhkType}
            />
          </div>
          <Typography className={classes.listHeader}>Bathroom</Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              options={meta.bathRoomOption}
              onSelect={onChangeChipValue}
              default={chips.bathroom}
              name="bathRoom"
              value={chips.bathRoom}
            />
          </div>
          <Typography className={classes.listHeader}>Furnish Type</Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              options={meta.furnishType}
              onSelect={onChangeChipValue}
              default={chips.furnishType}
              name="furnishType"
              value={chips.furnishType}
            />
          </div>
          <Typography className={classes.listHeader}>Tenant Type</Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              options={meta.tenantType}
              onSelect={onChangeChipValue}
              default={chips.tenantType}
              name="tenantType"
              value={chips.tenantType}
            />
          </div>
          <Typography className={classes.listHeader}>Parking</Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              options={meta.parkingOption}
              onSelect={onChangeChipValue}
              default={chips.openParking}
              name="openParking"
              value={chips.openParking}
            />
          </div>

          <Typography className={classes.listHeader}>Amenities</Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              options={meta.amenity.map((v) => ({ ...v, isActive: false }))}
              onSelect={onChangeChipValue}
              default={chips.amenity}
              name="amenity"
              value={chips.amenity}
            />
          </div>

          <Typography className={classes.listHeader}>
            Property Description
          </Typography>
          <TextField
            className={classes.inputfiled}
            fullWidth
            name="propertyDesc"
            size="small"
            type="text"
            placeholder="Write Description"
            multiline
            rows={3}
            variant="outlined"
            InputProps={
              {
                // classes: {
                //   notchedOutline: classes.notchedOutline,
                //   focused: classes.focused,
                // },
              }
            }
            value={formik.values.propertyDesc}
            onChange={formik.handleChange}
            error={
              formik.touched.propertyDesc && Boolean(formik.errors.propertyDesc)
            }
            helperText={
              formik.touched.propertyDesc && formik.errors.propertyDesc
            }
          />
          <Typography className={classes.listHeader}>
          Property Name<span className={classes.req}> *</span>
        </Typography>
      
            <TextField
              className={classes.formControl}
              fullWidth
              autoComplete="off"
              size="small"              
              placeholder="Enter Property Name"
              rows={1}
              variant="outlined"
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
              }}
            />
          
        
          <Typography className={classes.listHeader}>
            Agreement Duration
          </Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              onSelect={onChangeChipValue}
              options={meta.agreementDuration}
              default={chips.agreementDuration}
              name="agreementDuration"
              value={chips.agreementDuration}
            />
          </div>
          <Typography className={classes.listHeader}>Property Age</Typography>
          <div className={classes.listChip}>
            <StyledChipGroupObj
              onSelect={onChangeChipValue}
              options={meta.propertyAge}
              default={chips.propertyAge}
              name="propertyAge"
            />
          </div>
          <Typography className={classes.listHeader}>Available From</Typography>

          {/*<TextField
                id="date"
                type="date"
                size="small"
                fullWidth
                variant="outlined"
                defaultValue="YYYY-MM-DD"
                className={classes.inputfiled}
                InputLabelProps={{
                  
                  shrink:true
                  }}
                  InputProps={{
                    style: {
                      height,
                    },
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      focused: classes.focused
                    },
                  }}
                />*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              fullWidth
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={selectedDate}
              style={{ width: "100%" }}
              InputProps={
                {
                  // classes: {
                  //   notchedOutline: classes.notchedOutline,
                  //   focused: classes.focused,
                  // },
                }
              }
              InputAdornmentProps={{ position: "end" }}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
          <Typography className={classes.listHeader}>Monthly Rent</Typography>
          <TextField
            className={classes.inputfiled}
            fullWidth
            //value={rent}
            value={formik.values.rentAmount}
            onChange={formik.handleChange}
            error={
              formik.touched.rentAmount && Boolean(formik.errors.rentAmount)
            }
            helperText={formik.touched.rentAmount && formik.errors.rentAmount}
            //onChange={(e) => setRent(e.target.value)}
            name="rentAmount"
            size="small"
            InputProps={{
              // classes: {
              //   notchedOutline: classes.notchedOutline,
              //   focused: classes.focused,
              // },
              startAdornment: (
                <InputAdornment position="start">Rs</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">Month</InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <Typography className={classes.listHeader}>Advance Amount</Typography>
          <TextField
            className={classes.inputfiled}
            fullWidth
            name="advanceAmount"
            size="small"
            InputProps={{
              // classes: {
              //   notchedOutline: classes.notchedOutline,
              //   focused: classes.focused,
              // },
              startAdornment: (
                <InputAdornment position="start">Rs</InputAdornment>
              ),
            }}
            variant="outlined"
            value={formik.values.advanceAmount}
            onChange={formik.handleChange}
            error={
              formik.touched.advanceAmount &&
              Boolean(formik.errors.advanceAmount)
            }
            helperText={
              formik.touched.advanceAmount && formik.errors.advanceAmount
            }
          />
          <Typography className={classes.listHeader}>
            Maintenance Charge
          </Typography>
          <TextField
            className={classes.inputfiled}
            fullWidth
            name="maintenanceCost"
            size="small"
            InputProps={{
              // classes: {
              //   notchedOutline: classes.notchedOutline,
              //   focused: classes.focused,
              // },
              startAdornment: (
                <InputAdornment position="start">Rs</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">Month</InputAdornment>
              ),
            }}
            variant="outlined"
            value={formik.values.maintenanceCost}
            onChange={formik.handleChange}
            error={
              formik.touched.maintenanceCost &&
              Boolean(formik.errors.maintenanceCost)
            }
            helperText={
              formik.touched.maintenanceCost && formik.errors.maintenanceCost
            }
          />
          <Typography className={classes.listHeader}>Area Size</Typography>
          <TextField
            className={classes.inputfiled}
            fullWidth
            name="propertyArea"
            size="small"
            InputProps={{
              // classes: {
              //   notchedOutline: classes.notchedOutline,
              //   focused: classes.focused,
              // },
              endAdornment: (
                <InputAdornment position="end">Sq.ft</InputAdornment>
              ),
            }}
            variant="outlined"
            value={formik.values.propertyArea}
            onChange={formik.handleChange}
            error={
              formik.touched.propertyArea && Boolean(formik.errors.propertyArea)
            }
            helperText={
              formik.touched.propertyArea && formik.errors.propertyArea
            }
          />
          <Typography className={classes.listHeader}>Contact Number</Typography>
          <TextField
            className={classes.inputfiled}
            fullWidth
            name="contactNumber"
            size="small"
            variant="outlined"
            InputProps={
              {
                // classes: {
                //   notchedOutline: classes.notchedOutline,
                //   focused: classes.focused,
                // },
              }
            }
            value={formik.values.contactNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.contactNumber &&
              Boolean(formik.errors.contactNumber)
            }
            helperText={
              formik.touched.contactNumber && formik.errors.contactNumber
            }
          />
        </form>
      )}
    </div>
  );
}
