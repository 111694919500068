const GOOGLE_MAP_API_KEY = "AIzaSyCe1CIbSG7kwUh7m4G7ZLFGMLT_9984NuU";

const CompanyNAme = "OWNNTEN";
const HOME_IMAGE =
  "https://ik.imagekit.io/emdzb8mhpw/gummy-sweet-home_k-8Ek3eYq.svg";

const meta = {
  razorPayKey: [
    {
        "id": 1,
        "value": "rzp_live_UYZmz1wzaqLsPK"
    }
],
  docTypes: [
    {
      id: "OWNER_AGREEMENT",
      value: "Owner Agreement",
    },
    {
      id: "TENANT_AGREEMENT",
      value: "Tenant Agreement",
    },
    {
      id: "OTHERS",
      value: "Others",
    },
  ],
  docKycTypes:[
    {
      id:"AADHAAR_CARD",
      value:"Aadhaar Card",
    },
    {
      id:"VOTER_ID_CARD",
      value:"Voter ID Card",
    },
    {
      id:"PAN_CARD",
      value:"PAN Card",
    },
    {
      id:"DRIVING_LICENSE_INDIA",
      value:"Driving License in India",
    },
    {
      id:"OTHERS",
      value:"Others",
    },
  ],
  propertyAge: [
    {
      id: 1,
      value: "Under Construction",
    },
    {
      id: 2,
      value: "<5 years",
    },
    {
      id: 3,
      value: ">5 years",
    },
  ],
  tenantType: [
    {
      id: 1,
      value: "Bachelor",
    },
    {
      id: 2,
      value: "Family",
    },
    {
      id: 3,
      value: "Any",
    },
  ],
  propertyStatus: [
    {
      id: 2,
      value: "Reviewed",
    },
    {
      id: 1,
      value: "Drafted",
    },
  ],
  apiKey: [
    {
      id: 1,
      value: "AIzaSyBO0wXydFoRerr_yvy4UAbuP-8wXKgotbY",
    },
    {
      id: 2,
      value: "AIzaSyCe1CIbSG7kwUh7m4G7ZLFGMLT_9984NuU",
    },
  ],
  amenity: [
    {
      id: 1,
      value: "Air-Conditioner",
    },
    {
      id: 2,
      value: "Club",
    },
    {
      id: 3,
      value: "Play Ground",
    },
    {
      id: 4,
      value: "Gas",
    },
    {
      id: 5,
      value: "Rain Water Harvesting",
    },
    {
      id: 6,
      value: "GYM",
    },
  ],
  propertyCategory:[
    {
      id: 1,
      value: "Commercial",
    },
    {
      id:2,
      value: "Residential",
    },
  ],
  propertyContactType:[
    {
      id: 1,
      value: "Father/Mother",
    },
    {
      id: 2,
      value: "Son/Daughter",
    },
    {
      id: 3,
      value: "Friend",
    },
    {
      id: 4,
      value: "Husband/Wife",
    },
    {
      id: 5,
      value: "Brother/Sister",
    },
    {
      id: 6,
      value: "Other",
    },
    
  ],
  propertyType: [
    {
      id: 1,
      value: "Apartment",
    },
    {
      id: 2,
      value: "Independent House/Villa",
    },
    {
      id: 3,
      value: "Gated Community Villa",
    },
  ],
  bhkType: [
    {
      id: 1,
      value: "1 RK",
    },
    {
      id: 2,
      value: "1 BHK",
    },
    {
      id: 3,
      value: "2 BHK",
    },
    {
      id: 4,
      value: "3 BHK",
    },
    {
      id: 5,
      value: "4 BHK",
    },
    {
      id: 6,
      value: "4+ BHK",
    },
  ],
  furnishType: [
    {
      id: 1,
      value: "Semi Furnished",
    },
    {
      id: 2,
      value: "Fully Furnished",
    },
    {
      id: 3,
      value: "Not Furnished",
    },
  ],
  securityDepositOption: [
    {
      id: 1,
      value: "Yes",
    },
    {
      id: 2,
      value: "No",
    },
  ],
  bathRoomOption: [
    {
      id: 1,
      value: "1",
    },
    {
      id: 2,
      value: "2",
    },
    {
      id: 3,
      value: "3",
    },
    {
      id: 4,
      value: "3+",
    },
  ],
  agreementDuration: [
    {
      id: 1,
      value: "11 Months",
    },
    {
      id: 2,
      value: "1 Year",
    },
    {
      id: 3,
      value: ">1 Year",
    },
  ],
  parkingOption: [
    {
      id: 1,
      value: "1",
    },
    {
      id: 2,
      value: "2",
    },
    {
      id: 3,
      value: "3",
    },
    {
      id: 4,
      value: "3+",
    },
  ],
};
const MARKER_ICON = "https://ik.imagekit.io/emdzb8mhpw/house_uL07RWWqE.svg";

const SMS = {
  username: "oneassist.net",
  password: "34902283",
  source: "ONEATS",
  dltentityid: "1501573880000019506",
  dltheaderid: "1505160854309326523",
  dlttempid: "1507161743048084576",
  messageurl: "https://www.txtguru.in/imobile/api.php",
  token:
    "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VyMSIsInJvbGVzIjoiUk9MRV9BRE1JTiIsImlhdCI6MTYxODM3NTEzOSwiZXhwIjoxODE2MTgzNzUxMzl9.NHc53mzb8MrPZy9cxtbveFFdWias8sRIcDOJnRW_tZg",
};

const AGREEMENT_POINTS = [
  "That the Lessor hereby grant to the Lessee, the right to enter into and use and remain in the said premises along with the existing fixtures and fittings listed in Annexure 1 to this Agreement and that the Lessee shall be entitled to peacefully possess, and enjoy possession of the said premises, and the other rights herein.",
  "That the lease hereby granted shall, unless cancelled earlier under any provision of this Agreement, remain in force for a period of {data.agreementDuration}. ",
  "That the Lessee will have the option to terminate this lease by giving {data.noticePeriod} month`s notice in writing to the Lessor.",
  "That the Lessee shall have no right to create any sub-lease or assign or transfer in any manner the lease or give to any one the possession of the said premises or any part thereof.",
  "That the Lessee shall use the said premises only for residential purposes.",
  "That the Lessor shall, before handing over the said premises, ensure the working of sanitary, electrical and water supply connections and other fittings pertaining to the said premises. It is agreed that it shall be the responsibility of the Lessor for their return in the working condition at the time of re-possession of the said premises (reasonable wear and tear and loss or damage by fire, flood, rains, accident, irresistible force or act of God excepted).",
  "That the Lessee is not authorized to make any alteration in the construction of the said premises. The Lessee may however install and remove his own fittings and fixtures, provided this is done without causing any excessive damage or loss to the said premises.",
  "That the day to day repair jobs such as fuse blow out, replacement of light bulbs/tubes, leakage of water taps, maintenance of the water pump and other minor repairs, etc., shall be effected by the Lessee at its own cost, and any major repairs, either structural or to the electrical or water connection, plumbing leaks, water seepage shall be attended to by the Lessor. In the event of the Lessor failing to carry out the repairs on receiving notice from the Lessee, the Lessee shall undertake the necessary repairs and the Lessor will be liable to immediately reimburse costs incurred by the Lessee.",
  "That the Lessor or its duly authorized agent shall have the right to enter into or upon the said premises or any part thereof at a mutually arranged convenient time for the purpose of inspection.",
  "That the Lessee shall use the said premises along with its fixtures and fitting in careful and responsible manner and shall handover the premises to the Lessor in working condition (reasonable wear and tear and loss or damage by fire, flood, rains, accidents, irresistible force or act of God excepted).",
  "That in consideration of use of the said premises the Lessee agrees that he shall pay to the Lessor during the period of this agreement, a monthly rent at the rate of [Monthly Rental in Number & Words]. The amount will be paid in advance on or before the date of [1st day] of every English calendar month.",
  "It is hereby agreed that if default is made by the lessee in payment of the rent for a period of three months, or in observance and performance of any of the covenants and stipulations hereby contained and on the part to be observed and performed by the lessee, then on such default, the lessor shall be entitled in addition to or in the alternative to any other remedy that may be available to him at this discretion, to terminate the lease and eject the lessee from the said premises; and to take possession thereof as full and absolute owner thereof, provided that a notice in writing shall be given by the lessor to the lessee of his intention to terminate the lease and to take possession of the said premises. If the arrears of rent are paid or the lessee comply with or carry out the covenants and conditions or stipulations, within fifteen days from the service of such notice, then the lessor shall not be entitled to take possession of the said premises.",
  "That in addition to the compensation mentioned above, the Lessee shall pay the actual electricity, shared maintenance, water bills for the period of the agreement directly to the authorities concerned. The relevant `start date` meter readings are [Starting Meter Reading].",
  "That the Lessee has paid to the Lessor a sum of [Rental Deposit in Number and Words] as deposit, free of interest, which the Lessor does accept and acknowledge. This deposit is for the due performance and observance of the terms and conditions of this Agreement. The deposit shall be returned to the Lessee simultaneously with the Lessee vacating the said premises. In the event of failure on the part of the Lessor to refund the said deposit amount to the Lessee as aforesaid, the Lessee shall be entitled to continue to use and occupy the said premises without payment of any rent until the Lessor refunds the said amount (without prejudice to the Lessee`s rights and remedies in law to recover the deposit).",
  "That the Lessor shall be responsible for the payment of all taxes and levies pertaining to the said premises including but not limited to House Tax, Property Tax, other cesses, if any, and any other statutory taxes, levied by the Government or Governmental Departments. During the term of this Agreement, the Lessor shall comply with all rules, regulations and requirements of any statutory authority, local, state and central government and governmental departments in relation to the said premises.",
  "That the TENANT has paid to the LANDLORD a sum of [Rental Deposit in Number and Words][mode of payment and date of payment] as deposit, without carrying any interest, which the LANDLORD does accept and acknowledge. This deposit is for the due performance and observance of the terms and conditions of this Agreement. The deposit shall be returned to the TENANT simultaneously with the TENANT vacating the said premises. In the event of failure on the part of the LANDLORD to refund the said deposit amount to the TENANT as aforesaid, the TENANT shall sue the LANDLORD in court the court of Law in the applicable Jurisdiction.",
  "That the LANDLORD shall be responsible for the payment of all taxes and levies pertaining to the said premises including but not limited to House Tax, Property Tax, other cesses, if any, and any other statutory taxes, levied by the Government or Governmental Departments. During the term of this Agreement, the LANDLORD shall comply with all rules, regulations and requirements of any statutory authority, local, state and central government and governmental departments in relation to the said premises.",
];
const AGREEMENT_FINAL_POINT =
  "Whatever terms and conditions are mentioned in the rental agreement is abiding to both the parties and these terms and conditions are final. These agreed conditions are final and irrevocable.";

export {
  GOOGLE_MAP_API_KEY,
  CompanyNAme,
  HOME_IMAGE,
  meta,
  MARKER_ICON,
  SMS,
  AGREEMENT_POINTS,
  AGREEMENT_FINAL_POINT,
};
