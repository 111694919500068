import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { meta } from "../../components/Constant";
import { UserContext } from "../../context/UserContext";
import API from "../../helper/rest";
//import TenantListTable from "./TenantListTable";

const useStyles = makeStyles((theme) => ({
  textRight: {
    float: "right",
  },
  heading: {
    marginBottom: "1rem",
  },
}));

export default function Wallet() {
  const classes = useStyles();
  const [walletAmount, setWalletAmount] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState({
    balanceAmount: 0,
    earned: 0,
  });
  const userDetails = useContext(UserContext);

  const loadWallet = () => {
    setLoading(true);
    API.post("/wallet-details", {
      userId: userDetails.userId,
    })
      .then((response) => {
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const makePayment = async () => {
    setShowPopup(false);
    if (isNaN(walletAmount) || walletAmount < 1000) {
      alert("Please enter a valid amount");
      return;
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let option = {
      amount: walletAmount * 100,
      currency: "INR",
      userId: userDetails.userId,
      transfers: [],
    };
    setLoading(true);
    var result = await API.post("payment/createpaymentorder", option);
    if (!result) {
      setLoading(false);
      alert("Server error. Are you online?");
      return;
    }
    setLoading(false);

    const { amount, id: order_id, currency } = result.data.response;

    const options = {
      key: meta.RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount + "",
      currency: currency,
      name: "Ownnten",
      description: "Test Transaction",
      image: "",
      order_id: order_id,
      handler: async function (response) {
        const data = {
          id: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          status: "SUCCESS",
        };
        // const data = { id: order_id, status: "SUCCESS" };
        const result = await API.post("/payment/updatepaymentstatus", data);
        if (!result) {
          setLoading(false);
          alert("Server error. Are you online?");
          return;
        }
        setLoading(false);
        alert("Paid");
      },
      prefill: {
        name: userDetails.userName,
        email: userDetails.userEmail,
        contact: userDetails.userMobile,
      },
      notes: {
        userId: userDetails.userId,
        address: "Example Corporate Office",
      },
      theme: {
        color: "#772360",
      },
      modal: {
        ondismiss: function () {
          setLoading(false);
          //changeOrderStatus(order_id, "dismised", "modal closed");
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
      // changeOrderStatus(
      //   response.error.metadata.order_id,
      //   "failed",
      //   response.reason
      // );
      setLoading(false);
    });
    setLoading(true);
    paymentObject.open();
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const handleClickOpen = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    loadWallet();
  }, []);

  return (
    <div className={classes.heading}>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <Typography gutterBottom variant="h6">
            My Wallet
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            className={classes.textRight}
            variant="outlined"
            color="primary"
            onClick={() => handleClickOpen()}
          >
            Add Money
          </Button>
          <Dialog
            open={showPopup}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Add Money to wallet
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This amount can be withdrawn at any time.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="amount"
                label="Amount"
                type="number"
                value={walletAmount}
                onChange={(e) => setWalletAmount(e.target.value)}
                fullWidth
                helperText="Minimum amount is 1000"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={makePayment} color="primary">
                Add Money
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <div className="r-card r-card-4">
            <h3>Rs.{wallet.balanceAmount}</h3>
            <p>Balance</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className="r-card r-card-3">
            <h3>Rs.{wallet.earned}</h3>
            <p>Earned</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
